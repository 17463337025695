import * as React from 'react'
import loadable from '@loadable/component'

const FormPage = loadable(() => import('/src/templates/VoucherFormPage'))

const Page = ({ postInfo }) => {
    const post = {
        canonical: '/',
        title: 'Compare <span>Life Insurance</span> Quotes - from £5/Month*',
        voucherCallout: 'Buy through us & claim a Lifestyle voucher from £30 to £300**',
        voucherDisclaimer: '** See Lifestyle voucher <a target="_blank" class="c-link-internal" href="/lifestyle-voucher-terms-and-conditions">Terms and Conditions</a> for full details.',
        indexStatus: 'noindex',
        followStatus: 'nofollow'
    }

    return <FormPage postInfo={postInfo} post={post} leadSource={'SelfGen.CTQ.Voucher'}/>
}

export default Page
